import(/* webpackMode: "eager" */ "/vercel/path0/portal/app/providers.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--font-inter\",\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/font/local/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./CalSans-SemiBold.otf\",\"variable\":\"--font-cal\",\"weight\":\"600\",\"display\":\"swap\"}],\"variableName\":\"cal\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"Lora\",\"arguments\":[{\"variable\":\"--font-title\",\"subsets\":[\"latin\"],\"weight\":\"600\",\"display\":\"swap\"}],\"variableName\":\"lora\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"Be_Vietnam_Pro\",\"arguments\":[{\"variable\":\"--font-title\",\"subsets\":[\"latin\"],\"weight\":\"400\",\"display\":\"swap\"}],\"variableName\":\"beVN\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/src/stores/rootStoreProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/styles/custom-ant.module.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/styles/globals.scss");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/font/local/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./CalSans-SemiBold.otf\",\"variable\":\"--font-title\",\"weight\":\"600\",\"display\":\"swap\"}],\"variableName\":\"calTitle\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/portal/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts.ts\",\"import\":\"Work_Sans\",\"arguments\":[{\"variable\":\"--font-title\",\"subsets\":[\"latin\"],\"weight\":\"600\",\"display\":\"swap\"}],\"variableName\":\"work\"}")